import React from 'react'
import { graphql } from 'gatsby'
import GalleryPage from '../components/GalleryPage'

const EtchingsPage = ({ data }) => (
  <GalleryPage data={data} currentPath="/etchings" title="Etchings" />
)

export default EtchingsPage

export const query = graphql`
  query {
    allPicturesCsv(filter: { type: { eq: "etching" } }) {
      ...Pictures
    }
  }
`
